<template>
  <section class="container">
    <template v-if="type == 'temp'">
      <section class="box">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in list"
            :key="index"
            :timestamp="item.time ? item.time.substr(0, 10) : ''"
          >
          <div
            class="editBox"
            style="display: flex; justify-content: space-between"
          >
            <span class="edit" @click="editTemp(item)">
              <i class="el-icon-edit-outline"></i>
            </span>
            <span class="del" @click="delTemp(item)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
            <div class="tit">
              <p>体温{{ item.temperature }}度</p>
              <p style="width: calc(100% - 0.7rem)" v-if="item.isAbnormal == 1">
                 {{ item.abnormal ? item.abnormal.name==='其他'?item.remark:item.abnormal.name: "" }}
              </p>
            </div>
          </el-timeline-item>
        </el-timeline>
      </section>
    </template>
    <template v-else-if="type == 'isolate'">
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :timestamp="
            item.startTime.substr(0, 10) + '-' + item.endTime.substr(0, 10)
          "
          style="position: relative"
        >
          <div
            class="editBox"
            style="display: flex; justify-content: space-between"
          >
            <span class="edit" @click="editIsolate(item)">
              <i class="el-icon-edit-outline"></i>
            </span>
            <span class="del" @click="delIsolate(item)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <p style="width: calc(100% - 0.7rem)">隔离类型：{{ item.isolateType ? item.isolateType : "" }}</p>
          <p style="width: calc(100% - 0.7rem)">隔离地点：{{ item.abroadName + (item.province||'') + (item.city||'') + (item.district||'') +item.addressDetail }}</p>
        </el-timeline-item>
      </el-timeline>
    </template>
    <template v-else-if="type == 'vaccine'">
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :timestamp="item.time.substr(0, 10) + `   第${item.count || 1}针`"
          style="position: relative"
        >
          <div
            class="editBox"
            style="display: flex; justify-content: space-between"
          >
            <span class="edit" @click="editVaccine(item)">
              <i class="el-icon-edit-outline"></i>
            </span>
            <span class="del" @click="delVaccine(item)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <p style="width: calc(100% - 0.7rem)">接种类型：{{ item.vaccinesType ? item.vaccinesType.name : "" }}</p>
          <p style="width: calc(100% - 0.7rem)">接种地址：{{ item.address }}</p>
        </el-timeline-item>
      </el-timeline>
    </template>
    <template v-else>
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :timestamp="item.time.substr(0, 10)"
          style="position: relative"
        >
          <div
            class="editBox"
            style="display: flex; justify-content: space-between"
          >
            <span class="edit" @click="edit(item)">
              <i class="el-icon-edit-outline"></i>
            </span>
            <span class="del" @click="del(item)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <p style="width: calc(100% - 0.7rem)">检测地：{{ item.address }}</p>
          <p style="width: calc(100% - 0.7rem)">检测结果：{{ !item.result ? "阴性" : "阳性" }}</p>
        </el-timeline-item>
      </el-timeline>
    </template>
  </section>
</template>

<script>
import {
  getPersonDetail,
  getTempDetail,
  getIsolateDetail,
  getVaccineDetail,
  getNuclearDetail,
  VaccinesType,
  EpidemicSave,
  nucleicList,
  vaccinesList,
  vaccineDelete,
  vaccineEdit,
  DelVac,
  EditVac,
  DelIsolate,
  DelTemp,
  EditTemp
} from "@/api/api";
export default {
  data() {
    return {
      list: [],
      type: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let phone = this.$route.params.mobile;
      this.type = this.$route.query.type;
      switch (this.$route.query.type) {
        case "temp":
          this.getTempDetail(phone);
          document.title = "体温历史";
          break;
        case "isolate":
          this.getIsolateDetail(phone);
          document.title = "隔离历史";
          break;
        case "vaccine":
          this.getVaccineDetail(phone);
          document.title = "接种历史";
          break;
        case "nuclear":
          this.getNuclearDetail(phone);
          document.title = "检测历史";
          break;
      }
    },
    async getTempDetail(phone) {
      let res = await getTempDetail(phone);
      this.list = res.data.data;
    },
    async getIsolateDetail(phone) {
      let res = await getIsolateDetail(phone);
      this.list = res.data.data;
    },
    async getVaccineDetail(phone) {
      let res = await getVaccineDetail(phone);
      this.list = res.data.data;
    },
    async getNuclearDetail(phone) {
      let res = await getNuclearDetail(phone);
      this.list = res.data.data;
    },
    del(item) {
      this.$weui.confirm("确认删除检测记录？", {
        title: "提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function () {},
          },
          {
            label: "确定",
            type: "primary",
            onClick: () => {
              vaccineDelete(item.id).then((res) => {
                this.$weui.toast("删除成功", {
                  duration: 1500,
                  className: "custom-classname",
                });
                this.getList();
              });
            },
          },
        ],
      });
    },
    editTemp(item) {
      this.$router.push(
        `/temperature?isEdit=1&data=${JSON.stringify(item)}&id=${item.id}`
      );
    },
    edit(item) {
      this.$router.push(
        `/epidemic?isEdit=1&data=${JSON.stringify(item)}&id=${item.id}`
      );
    },
    editVaccine(item) {
      this.$router.push(
        `/vaccine?isEdit=1&data=${JSON.stringify(item)}&id=${item.id}`
      );
    },
    editIsolate(item) {
      this.$router.push(
        `/quarantine?isEdit=1&data=${JSON.stringify(item)}&id=${item.id}`
      );
    },
    delVaccine(item) {
      this.$weui.confirm("确认删除接种记录？", {
        title: "提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function () {},
          },
          {
            label: "确定",
            type: "primary",
            onClick: () => {
              DelVac(item.id).then((res) => {
                this.$weui.toast("删除成功", {
                  duration: 1500,
                  className: "custom-classname",
                });
                this.getList();
              });
            },
          },
        ],
      });
    },
    delIsolate(item) {
      this.$weui.confirm("确认删除隔离记录？", {
        title: "提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function () {},
          },
          {
            label: "确定",
            type: "primary",
            onClick: () => {
              DelIsolate(item.id).then((res) => {
                this.$weui.toast("删除成功", {
                  duration: 1500,
                  className: "custom-classname",
                });
                this.getList();
              });
            },
          },
        ],
      });
    },
    delTemp(item) {
      this.$weui.confirm("确认删除隔离记录？", {
        title: "提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function () {},
          },
          {
            label: "确定",
            type: "primary",
            onClick: () => {
              DelTemp(item.id).then((res) => {
                this.$weui.toast("删除成功", {
                  duration: 1500,
                  className: "custom-classname",
                });
                this.getList();
              });
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0.2rem;
}
.editBox {
  position: absolute;
  right: 0;
  top: 0.1rem;
  width: 0.6rem;
}
.editBox span {
  font-size: 0.2rem;
}
.edit {
  color: #2f7dcd;
}
.del {
  color: red;
}
</style>